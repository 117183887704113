import {useEffect, useState} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {connect, useDispatch} from 'react-redux'

import useMediaQuery from '@mui/material/useMediaQuery'

import {shuffle} from 'lodash'

import {get} from 'lodash'

import {useIsMounted} from '../../../hooks'

import { 
  makeGetPerformanceById,
  makeGetPerformanceTracks,
  makeGetPerformanceFetchedAssociations 
} from '../../../selectors'


import {show} from '../../../apis'


import {
  showAlert,
  setFetchedAssociations,
  addTracksToPerformance
} from '../../../actions'


import {
  // convertSpotifyTracksToCkordFormat,
  convertAppleMusicTracksToCkordFormat
} from '../../../helpers'

import {ThreeDotProgress, DetailPageTabMessage} from '../../generics'


import {CardList, CardListMobile} from '../../cards'




const styles = {
  root: css`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 20px;
  `,
  cardsContainer: css`
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;

    .message {
      width: 100%;
      font-size: 1rem;
      text-align: left;
      padding-left: 15px;
      margin-bottom: 15px;
    }
  `,
  progressContainer: css`
    height: 100%;
    width: 100%;
    margin-top: 20px;
  `
}






const PerformanceTracks = props => {

  const dispatch = useDispatch()

  const isMounted = useIsMounted()

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))



  const {
    tracks,
    isLoaded,
    showAlert,
    permission,
    performanceID,
    // spotifyLinksLength,
    appleMusicLinksLength,
    setFetchedAssociations,
    addTracksToPerformance
  } = props


  const [isFetching, setIsFetching] = useState(false)




  // Get Spotify previews of tracks if user has a Spotify link
  useEffect(() => {
    if (isLoaded) return


    // If there are no Spotify links, then make sure to capture that a fetch was made
    // so that the isLoaded flag is changed to true
    if (!Boolean(appleMusicLinksLength)) {
      setFetchedAssociations('PERFORMANCE', performanceID, 'tracks')
      return
    }


    setIsFetching(true)


    show('/services/apple-music/performances/' + performanceID + '/tracks').then(response => {
      const data = response.data

      // Format the Spotify tracks to match the Ckord tracks
      const ckordTracks = convertAppleMusicTracksToCkordFormat(data, '', performanceID, 'Event')


      // Update the store
      dispatch({type: 'TRACKS_INDEX_SUCCESS', payload: {data: ckordTracks}})
      addTracksToPerformance(performanceID, ckordTracks)


      if (isMounted.current) {
        setIsFetching(false)
      }
    })
    .catch(error => {
      showAlert('Something went wrong fetchig the tracks for this performance', 'error')

      if (isMounted.current) {
        setIsFetching(false)
      }
    })
  }, [
    dispatch,
    isLoaded, 
    isMounted,
    showAlert,
    performanceID,  
    // spotifyLinksLength,
    appleMusicLinksLength,
    setFetchedAssociations,
    addTracksToPerformance
  ])



  return (
    <div css={styles.root}>
      <section css={styles.cardsContainer}>
        {Boolean(tracks.length) &&
          <>
            {isExtraSmallScreen &&
              <CardListMobile
                type='track'
                cards={tracks}
                hasNextPage={false}
                fetchMoreItems={() => {}}
              />
            }

            {!isExtraSmallScreen &&
              <CardList
                type='track'
                cards={tracks}
                hasNextPage={false}
                fetchMoreItems={() => {}}
              />
            }
          </>
        }



        {!Boolean(tracks.length) && isLoaded &&
          <DetailPageTabMessage>
            {['owner', 'editor'].includes(permission) 
              ? 'To attach tracks to this performance either add Spotify links in the performance form or invite performers to this performance'
              : 'This event has no tracks listed yet.'
            }
          </DetailPageTabMessage>
        }


        {isFetching && 
          <div css={styles.progressContainer}>
            <ThreeDotProgress position='sticky' /> 
          </div>
        }
      </section>
    </div>
  )
}




const makeMapStateToProps = () => {
  const getPerformanceById = makeGetPerformanceById()
  const getPerformanceTracks = makeGetPerformanceTracks()
  const getFetchedAssociations = makeGetPerformanceFetchedAssociations()


  const mapStateToProps = (state, props) => {

    const id = props.performanceID

    const performance = getPerformanceById(state, id) || {}

    const tracks = getPerformanceTracks(state, id)

    const role = get(state, ['user', 'attributes', 'role'])



    return {
      tracks: tracks,
      performanceID: id,
      permission: ['admin', 'editor'].includes(role) ? 'owner' : performance?.attributes.permission,
      isLoaded: getFetchedAssociations(state, id).includes('tracks'),
      // spotifyLinksLength: performance.attributes?.spotifyLinks?.length,
      appleMusicLinksLength: performance.attributes?.appleMusicLinks?.length
    }
  }

  return mapStateToProps
}


const actions = {showAlert, setFetchedAssociations, addTracksToPerformance}


export default connect(makeMapStateToProps, actions)(PerformanceTracks)



